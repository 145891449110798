/* latin-ext */
@font-face {
  font-family: '__Righteous_52d058';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f6ffe4459c1daa33-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Righteous_52d058';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f769c49a4b8c1350-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Righteous_Fallback_52d058';src: local("Arial");ascent-override: 92.55%;descent-override: 24.14%;line-gap-override: 0.00%;size-adjust: 106.42%
}.__className_52d058 {font-family: '__Righteous_52d058', '__Righteous_Fallback_52d058';font-weight: 400;font-style: normal
}.__variable_52d058 {--font-righteous: '__Righteous_52d058', '__Righteous_Fallback_52d058'
}

